export const NAVIGATION = {
  workforce: [
    {
      id: 1,
      routeName: "listCampaign",
      text: "Campañas",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "commissions",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 3,
      routeName: "activity",
      text: "Actividad",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 5,
      routeName: "changePassword",
      text: "Cambiar contraseña",
      count: true,
      icon: "",
      subItem: [],
    },
  ],
  directory: [
    {
      id: 1,
      routeName: "commissionsDirectory",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "changePassword1",
      text: "Cambiar contraseña",
      count: true,
      icon: "",
      subItem: [],
    },
  ],
  manager: [
    {
      id: 1,
      routeName: "commissionsManager",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "changePassword2",
      text: "Cambiar contraseña",
      count: true,
      icon: "",
      subItem: [],
    },
  ],
  payRollManager: [
    {
      id: 1,
      routeName: "commissionsPayRollManager",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "changePassword3",
      text: "Cambiar contraseña",
      count: true,
      icon: "",
      subItem: [],
    },
  ],
};

export const CATEGORY = {
  featured: 15,
  otherNews: 16,
};

export const TYPEFORM = {
  // INPUT_TEXT : "first_name",
  // INPUT_TEXT1 : "middle_name",
  // INPUT_TEXT2 : "last_name",
  // INPUT_MAIL : "email",
  // INPUT_TEXT3: "input_text",
  // INPUT_TEXT4 : "input_mask",
  // INPUT_DATE : "datetime",
  // TEXTAREA : "description",
  // INPUT_NUMBER : "numeric_field",
  // UPLOAD_IMG: "image-upload",
  // UPLOAD_FILE: "file-upload",
  // SELECT: "multi_select",
  // CHECKBOX: "checkbox",
  // RADIO: "input_radio",
  // ADDRESS: "address_1",
  // SELECT_COUNTRY: "country_list",
  // URL: "url",
  // DROPDOWN : "dropdown",

  INPUT_TEXT: "text",
  INPUT_MAIL: "email",
  INPUT_DATE: "datetime",
  INPUT_PASSWORD: "password",
  TEXTAREA: "textarea",
  INPUT_NUMBER: "number",
  URL: "url",
  HTML: "custom_html",
  SELECT: "select",
  SELECT_COUNTRY: "select_country",
  SUBMIT: "submit",
  RADIO: "radio",
  SECTION_BREAK: "section_break",
  MULTI_SELECT: "multi_select",
  CHECK_GDPR: "gdpr-agreement",
  CHECK_TERM: "terms_n_condition",
  CHECKBOX: "checkbox",
  HIDDEN: "hidden",
  IMG: "file",
  UPLOAD_FILE: "file-document",
};

export const STATUS = {
  APPROVAL: 11,
  REJECTED: 10,
  PENDING: 5
};

