<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="extra__title">Solicitud de horas extras</h1>
        </v-col>
      </v-row>
      <v-card outlined class="extra__card px-8 mt-8" elevation="0">
        <!-- Filtros -->
        <v-row class="mt-2">
          <v-col cols="3">
            <v-autocomplete
              :items="listType"
              label="Tipo de consulta"
              v-model="type"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              :items="listStatus"
              label="Estado"
              v-model="statusId"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="search" label="Buscar">
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="openModal()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
                class="mt-2 ml-2"
              >
                <strong> Registrar solicitud </strong>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
        <!-- Tabla -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :search="search"
              :loading="loading.table"
              :items-per-page="15"
              style="background-color: #f9f9f900"
              :headers="headers"
              :items="items"
              item-key="name"
              class="elevation-0 extra__table"
              :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
            >
              <!-- Sin resultados de busqueda -->
              <template v-slot:no-results>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontraron resultados</b>
                </v-alert>
              </template>
              <!-- Sin Data -->
              <template v-slot:no-data>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontró información.</b>
                </v-alert>
              </template>
              <!-- Loading -->
              <template v-slot:progress>
                <v-progress-linear
                  color="#214484"
                  :height="5"
                  indeterminate
                  stream
                ></v-progress-linear>
              </template>

              <!-- Estado -->

              <template v-slot:item.statusColor="{ item }">
                <v-chip small :color="getColorStatus(item.status.name)" dark>
                  {{ item.status.name }}
                </v-chip>
              </template>

              <!-- Actions -->

              <template v-slot:item.actions="{ item }">
                <template v-if="type == 1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="openModalDelete(item)"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        icon
                      >
                        <v-icon color="#ff9900"> mdi-delete-outline </v-icon>
                      </v-btn>
                    </template>
                    <span> Cancelar solicitud </span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <template v-if="item.status_id == status.PENDING">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          @click="openModalApproval(item, 1)"
                          v-bind="attrs"
                          v-on="on"
                          fab
                          icon
                          color="#466BE3"
                        >
                          <v-icon> mdi-check-circle </v-icon>
                        </v-btn>
                      </template>
                      <span> Aprobar solicitud </span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="item.status_id == status.PENDING"
                          small
                          @click="openModalApproval(item, 2)"
                          v-bind="attrs"
                          v-on="on"
                          fab
                          icon
                          color="red"
                        >
                          <v-icon> mdi-close-circle </v-icon>
                        </v-btn>
                      </template>
                      <span> Rechazar solicitud </span>
                    </v-tooltip>
                  </template>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-dialog v-model="dialog.add" max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <p class="extra___title-dialog mb-5">
            Agregar solicitud de horas extras
          </p>
          <v-form ref="form">
            <p class="extra__txt-field mb-1 mt-2">Fecha de inicio *</p>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="form.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.date"
                  label="Fecha de inicio"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date" no-title scrollable :min="now">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(form.date)">
                  Seleccionar
                </v-btn>
              </v-date-picker>
            </v-menu>

            <p class="extra__txt-field mb-1 mt-2">Hora inicio *</p>

            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.time1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.time1"
                  label="Hora de inicio"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  format="24hr"
                ></v-text-field>
              </template>
              <v-time-picker
                min="6:00"
                max="23:00"
                format="24hr"
                v-if="menu2"
                v-model="form.time1"
                full-width
                @click:minute="$refs.menu2.save(form.time1)"
              ></v-time-picker>
            </v-menu>

            <p class="extra__txt-field mb-1 mt-2">Hora fin *</p>

            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.time2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.time2"
                  label="Hora de fin"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  format="24hr"
                ></v-text-field>
              </template>
              <v-time-picker
                :min="form.time1"
                max="23:00"
                format="24hr"
                v-if="menu3"
                v-model="form.time2"
                full-width
                @click:minute="$refs.menu3.save(form.time2)"
              ></v-time-picker>
            </v-menu>

            <p class="extra__txt-field mb-1 mt-2">Total horas</p>
            <v-text-field
              v-model="form.count"
              label="Hora de fin"
              prepend-inner-icon="mdi-clock-time-four-outline"
              readonly
              disabled
            ></v-text-field>
            <p class="extra__txt-field mb-1 mt-2">
              Razón por la cual requiere horas extras *
            </p>

            <v-textarea label="Razón" rows="3" v-model="form.reason">
            </v-textarea>

            <p class="extra__txt-field mb-1 mt-2 text-left">Jefe inmediato *</p>
            <v-autocomplete
              color="#466be3"
              v-model="form.userId"
              :items="listUsers"
              label="Seleccione respuesta"
              item-text="user_full_name"
              return-object
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>

            <v-alert
              v-if="infoUsers.full_name == ''"
              text
              type="info"
              class="mt-3"
            >
              Seleccione un jefe inmediato
            </v-alert>
            <v-row v-else class="mt-2 mb-3">
              <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="mb-0 mt-0">
                <v-layout justify-center>
                  <v-avatar
                    style="
                      cursor: pointer;
                      border-radius: 50%;
                      border: 2px solid;
                      border-color: #466be3;
                    "
                    class="voluntary__img mt-2"
                    size="100"
                  >
                    <v-img :src="infoUsers.user_img"> </v-img>
                  </v-avatar>
                </v-layout>
              </v-col>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8">
                <v-row>
                  <v-col
                    class="text-left pl-2 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pr-12 pt-2 pb-2"
                    cols="12"
                  >
                    <p class="mb-1 voluntary__data_title">
                      <i> Nombres completos </i>
                    </p>
                    <p class="mb-1 voluntary__data_txt camel-case">
                      {{ toTitleCase(infoUsers.user_full_name) }}
                    </p>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col
                    class="text-left pl-2 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pr-12 pt-2 pb-2"
                    cols="12"
                  >
                    <p class="mb-1 voluntary__data_title">
                      <i> Cargo </i>
                    </p>
                    <p class="mb-1 voluntary__data_txt camel-case">
                      {{ toTitleCase(infoUsers.profile_name) }}
                    </p>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-4">
            <v-col cols="6">
              <v-btn
                rounded
                block
                outlined
                color="#466BE3"
                @click="dialog.add = false"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                rounded
                block
                dark
                color="#466BE3"
                @click="saveForm()"
              >
                <strong> REGISTRAR </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.delete" max-width="500">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <v-layout justify-center>
            <img
              src="../../assets/icon/alert.svg"
              alt="img success"
              width="80"
            />
          </v-layout>
        </v-card-title>
        <v-card-text class="mt-1">
          <p class="pb-1 mb-1">
            <strong style="font-size: 19px">
              ¿Estas seguro de eliminar la solicitud?
            </strong>
          </p>
          <p class="pb-1 mb-1">
            Una vez realizada esta acción, no se puede recuperar la información
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-5">
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="dialog.delete = false"
                block
                outlined
                rounded
              >
                <strong> Cancelar </strong>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="deleteRequest()"
                block
                dark
                rounded
              >
                <strong> Eliminar </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.approval" max-width="500">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <v-layout justify-center>
            <img
              src="../../assets/icon/success.svg"
              alt="img success"
              width="80"
            />
          </v-layout>
        </v-card-title>
        <v-card-text class="mt-1">
          <p class="pb-1 mb-1">
            <strong style="font-size: 19px">
              ¿Estas seguro de aprobar la solicitud?
            </strong>
          </p>
          <p class="pb-1 mb-1">
            Tenga en cuenta que al aprobar la solicitud, estará aceptando que el
            colaborador realice {{ numHours }} horas extras.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-3">
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="dialog.approval = false"
                block
                outlined
                rounded
              >
                <strong> Cancelar </strong>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="saveStatus(status.APPROVAL)"
                block
                dark
                rounded
              >
                <strong> Aprobar </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.rejected" max-width="500">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <v-layout justify-center>
            <img
              src="../../assets/icon/error.svg"
              alt="img success"
              width="80"
            />
          </v-layout>
        </v-card-title>
        <v-card-text class="mt-1">
          <p class="pb-1 mb-1">
            <strong style="font-size: 19px">
              ¿Estas seguro de rechazar la solicitud?
            </strong>
          </p>
          <p class="pb-1 mb-1">
            Tenga en cuenta que al rechazar la solicitud, estará negando que el
            colaborador realice {{ numHours }} horas extras.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-3">
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="dialog.rejected = false"
                block
                outlined
                rounded
              >
                <strong> Cancelar </strong>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="#466BE3"
                @click="saveStatus(status.REJECTED)"
                block
                dark
                rounded
              >
                <strong> Aprobar </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import moment from "moment";
import { STATUS } from "@/utils/util.js";
export default {
  components: {
    Alert,
    moment,
  },
  mixins: [color],
  data() {
    return {
      status: STATUS,
      now: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialog: {
        add: false,
        delete: false,
        approval: false,
        rejected: false,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      items: [],
      headers: [],
      type: 1,
      listType: [
        {
          id: 1,
          name: "Solicitudes propias",
        },
        {
          id: 2,
          name: "solicitudes de colaboradores",
        },
      ],
      listStatus: [],
      listBonus: [],
      listProfile: [],
      loadingAll: false,
      token: "",
      infoToken: "",
      name: "",
      statusId: "",
      search: "",
      preference: true,
      listUsers: [],
      infoUsers: {
        profile_level: null,
        profile_name: "",
        profile_type: "",
        user_full_name: "",
        user_id: null,
        user_img: "",
        user_lastname: "",
        user_name: "",

        img: "",
        perfil: "",
        cc: "",
        scc: "",
        cliente: "",
        campaign: "",
        full_name: "",
      },
      form: {
        date: null,
        time1: null,
        time2: null,
        userId: null,
        count: null,
        reason: "",
      },
      menu2: false,
      menu3: false,
      menu: false,
      requestId: null,
      listC: [], // colaboradores
      listP: [], // porpias
      numHours: "",
    };
  },
  watch: {
    statusId: function () {
      this.getRequest();
    },
    type: function (n) {
      if (n) {
        if (n == 1) {
          this.headerP();
        } else if (n == 2) {
          this.headerC();
        }
      }
    },
    "form.userId": function (n) {
      if (n) {
        this.infoUsers = n;
      } else {
        this.infoUsers = {
          profile_level: null,
          profile_name: "",
          profile_type: "",
          user_full_name: "",
          user_id: null,
          user_img: "",
          user_lastname: "",
          user_name: "",
        };
      }
    },
    "form.time2": function () {
      const hora1 = moment(this.form.time1, "HH:mm:ss");
      const hora2 = moment(this.form.time2, "HH:mm:ss");
      this.form.count = hora2.diff(hora1, "hours");
      if (this.form.time1 != this.form.time2 && this.form.count == 0) {
        this.form.count = 1;
      }
    },
  },
  computed: {},
  methods: {
    headerP() {
      this.items = this.listP;
      this.headers = [
        {
          text: "Jefe inmediato",
          value: "boss.full_name",
          class: "extra__header",
        },
        {
          text: "Fecha de incio",
          value: "init_date",
          class: "extra__header",
        },
        {
          text: "Hora inicio",
          value: "init_time",
          class: "extra__header",
        },
        {
          text: "Hora fin",
          value: "final_time",
          class: "final_time",
        },
        {
          text: "# horas",
          value: "count_hours",
          class: "extra__header",
        },
        {
          text: "Motivo",
          value: "reason",
          class: "extra__header",
        },
        {
          text: "Estado",
          value: "statusColor",
          class: "extra__header",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "extra__header",
        },
      ];
    },
    headerC() {
      this.items = this.listC;
      this.headers = [
        {
          text: "Nombre del colaborador",
          value: "user.full_name",
          class: "extra__header",
        },
        {
          text: "Fecha de incio",
          value: "init_date",
          class: "extra__header",
        },
        {
          text: "Hora inicio",
          value: "init_time",
          class: "extra__header",
        },
        {
          text: "Hora fin",
          value: "final_time",
          class: "final_time",
        },
        {
          text: "# horas",
          value: "count_hours",
          class: "extra__header",
        },
        {
          text: "Motivo",
          value: "reason",
          class: "extra__header",
        },
        {
          text: "Estado",
          value: "statusColor",
          class: "extra__header",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "extra__header",
        },
      ];
    },
    openModal() {
      this.dialog.add = true;
      this.getBoss();
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getBoss() {
      this.loadingAll = true;
      Api.RetirementVoluntary()
        .getBoss(this.token)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            this.listUsers = res.data.data;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    openModalApproval(data, type) {
      if (type == 1) {
        this.dialog.approval = true;
      } else {
        this.dialog.rejected = true;
      }

      this.requestId = data.id;
      this.numHours = data.count_hours;
    },
    getRequest() {
      this.loadingAll = true;
      var data = {
        status_id: this.statusId,
      };
      Api.ExtraHours()
        .getExtraHours(this.token, data)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            // this.items = res.data.data.personal;
            this.listP = res.data.data.personal;
            this.listC = res.data.data.boss;
            this.type = 1;
            this.headerP();
          }
        })
        .catch((error) => {
          this.loadingAll = false;
        });
    },
    openModalDelete(data) {
      this.dialog.delete = true;
      this.requestId = data.id;
    },
    deleteRequest() {
      this.loadingAll = true;
      this.dialog.delete = false;
      var data = {
        id: this.requestId,
      };
      Api.ExtraHours()
        .deleteRequest(this.token, data)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            this.getRequest();
            this.message = {
              dialog: true,
              title: "¡EXCELENTE!",
              sms: res.data.message,
              type: "success",
              redirect: "",
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.dialog.delete = true;
          this.loadingAll = false;
        });
    },
    saveForm() {
      console.log("AQUI");
      this.dialog.add = false;
      // if (this.$refs.form.validate()) {
      var data = {
        init_date: this.form.date,
        final_date: this.form.date,
        init_time: this.form.time1,
        final_time: this.form.time2,
        count_hours: this.form.count,
        boss_id: this.form.userId.user_id,
        reason: this.form.reason,
      };
      console.log("ESTA AQUI");
      this.loadingAll = true;
      this.dialog.add = false;
      Api.ExtraHours()
        .saveExtraHours(this.token, data)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            console.log("se registró");
            this.getRequest();
            this.message = {
              dialog: true,
              title: "¡EXCELENTE!",
              sms: res.data.message,
              type: "success",
              redirect: "",
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.dialog.add = true;
          this.loadingAll = false;
        });
      // }
    },
    saveStatus(status) {
      this.loadingAll = true;
      console.log(status + " " + this.status.APPROVAL);
      if (status == this.status.APPROVAL) {
        this.dialog.approval = false;
      } else {
        this.dialog.rejected = false;
      }
      // this.dialog.delete = false;
      var data = {
        id: this.requestId,
        status_id: status,
      };
      Api.ExtraHours()
        .approvalRejectedRequest(this.token, data)
        .then((res) => {
          // this.loadingAll = false;
          this.dialog.approval = false;
          this.dialog.rejected = false;
          if (res.data.cod == 0) {
            this.getRequest();
            this.message = {
              dialog: true,
              title: "¡EXCELENTE!",
              sms: res.data.message,
              type: "success",
              redirect: "",
            };
          }
        })
        .catch((error) => {
          console.log(error);
          if (status == this.status.APPROVAL) {
            this.dialog.approval = true;
          } else {
            this.dialog.rejected = true;
          }
          this.loadingAll = false;
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.listStatus = [
      {
        id: this.status.PENDING,
        name: "Pendiente",
      },
      {
        id: this.status.APPROVAL,
        name: "Aprobado",
      },
      {
        id: this.status.REJECTED,
        name: "Rechazado",
      },
    ];
    this.getRequest();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.extra__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}
tr,
th,
span .extra__table .text-start .sortable {
  font-size: 16px !important;
}

.extra__card {
  border-radius: 15px !important;
}

.extra__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.extra__header {
  color: #353535 !important;
}

.extra___title-dialog {
  font-family: "AsapSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}

.extra__txt-field {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #353535;
}
</style>
